<script setup lang="ts">
import { CardProps } from "./Card.props"
import { iconsSrcMap } from "./Card.iconMapping"

const props = withDefaults(defineProps<CardProps>(), {
  theme: "green"
})

const route = useRoute()

const emit =
  defineEmits<{ (e: "onClose", iconType: CardProps["iconType"]): void }>()

const handleClickCard = () => {
  // navigateTo() is needed to make sure that query params are updated even if the cta is clicked when already inside the '/contatti' path

  if (route.fullPath.startsWith(customPaths.contactUs)) {
    if (props.iconType === "customerServiceChat") {
      navigateTo(customPaths.contactUs)
    }
    if (props.iconType === "customerServiceMail") {
      navigateTo(`${customPaths.contactUs}?help_subject=consulting_pharma`)
    }
  }

  emit("onClose", props.iconType)
}
</script>

<template>
  <div
    class="banner-help-utility-help_card flex flex-col rounded-lg"
    :class="{ 'bg-white p-4': theme === 'green' }"
  >
    <!-- Green Bar -->
    <div
      class="flex items-center gap-2 rounded"
      :class="{ 'bg-tiffany-10 p-4': theme === 'green' }"
    >
      <UtilsIcon
        v-if="iconType"
        v-bind="iconsSrcMap[iconType]"
        :class="theme === 'green' ? 'h-8 w-8' : 'h-12 w-12'"
        class="shrink-0"
      />
      <p class="pig-bold text-tiffany-400">
        {{ title }}
      </p>
    </div>

    <!-- Description -->
    <div class="flex h-full flex-col justify-between">
      <div>
        <UtilsMarkdown
          v-if="text"
          :class="theme === 'green' ? 'py-4' : 'py-2'"
          class="
            beaver
            markdown__link-underlined
            markdown__link-green-main
            markdown__strong-black-80
            markdown__link-hover-green-300
          "
          :content="text"
        />
      </div>

      <UtilsButton
        @click="handleClickCard"
        v-if="hrefContact"
        class="
          contact
          custom-btn-underlined
          custom-btn-no-padding
          custom-btn-pig-medium
          custom-btn-whitespace
          custom-btn-start
          custom-btn-hover-green-300
          cursor-pointer
          text-green-main
          underline
        "
        :path="hrefContact"
        :text="contact"
        theme="ghost-green"
        forceSelf
      />
    </div>
  </div>
</template>
